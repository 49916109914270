<template>
    <div class="main-container">

<!--      <Header />-->

      <div class="service-grid-slider-area section-space--inner--120">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="section-title-area text-center">
                <h2 class="section-title section-space--bottom--50">Kategoriler<span class="title-icon"></span></h2>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="row">
                <a v-if="genres" v-for="genre in genres" :href="'/kategori/' + genre.slug" class="badge bg-info text-white mr-1">{{ genre.name }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>

        <Movies />

        <!-- back to top start -->
        <back-to-top class="scroll-top" bottom="60px">
            <i class="ion-android-arrow-up"></i>
        </back-to-top>
        <!-- back to top end -->
    </div>
</template>

<script>

    import data from '../data/slider.json'
    import Movies from "@/views/Movies";
    import axios from "axios";

    export default {
        data () {
            return {
                data,
                genres:null
            }
        },
        components: {
          Movies,
        },
        metaInfo: {
            title: 'Castro - Home One',
            htmlAttrs: {
                lang: 'en',
                amp: true
            }
        },
      mounted() {
        axios
            .get("https://cd.tufangokmenler.com/api.php?key=list-genres")
            .then(response => (this.genres = response.data));
      }
    }
</script>
