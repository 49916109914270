<template>
  <!--====================  service grid slider area ====================-->
  <div class="service-grid-slider-area section-space--inner--120">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title-area text-center">
            <h2 class="section-title section-space--bottom--50">Son Ayda En Çok İzlenenler<span class="title-icon"></span></h2>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="row">
            <MovieComponent v-for="item in data.results" v-bind:key="item.id" :item="item"></MovieComponent>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--====================  End of service grid slider area  ====================-->
</template>

<script>

import axios from 'axios';
import MovieComponent from "@/components/MovieComponent";

export default {
  components: {
    MovieComponent
  },
  data() {
    return {
      data: {
        "results": null
      },
    }
  },
  mounted() {
    axios
        .get(this.apiUrl + "upcoming/" + this.apiEndPoint)
        .then(response => (this.data = response.data));
  }
};
</script>
