<template>
  <div class="col-4 mb-5">
    <a v-bind:href="'/movie/' + item.id " class="h-100 border text-decoration-none">
      <img v-bind:src="this.baseImageUrl + 'w400' + item.poster_path" alt="" class="img-fluid mb-4">
      <div class="px-4">
        <h4 class="font-weight-bold mt-4 mb-3">{{ item.title }}</h4>
        <p>{{ item.overview.substring(0,255) + ".." }}</p>
      </div>
    </a>

  </div>
</template>

<script>
  export default {
    name: "MovieComponent",
    props:["item"]
  }
</script>

<style scoped>

</style>
